import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import img from "../../../assets/events/internal Events Images/exploring-the-intersection.webp"
import img1 from "../../../assets/events/internal Events Images/exploring-the-intersection-1.webp"
import MainSubHeading from "../../Sub-Heading/MainSubHeading";
export default function eventTwo() {
  const subheadingData = "Events";
  return (
    
    <Container>
      <MainSubHeading subheading={subheadingData} />
      <div className="mt-4">
      <h1 className='text-center'>Prof P Anandanspoke on AI innovation for global health</h1>
        <p>
        Prof P. Anandan's talk on “Translating AI Innovations into Solutions for Global Health”, in January, explored the intersection of AI and global health solutions. Prof Anandan is an AI scientist with a focus on AI for social good.
        </p>
      </div>
      <div className="img-content text-center">
      <Row>
          <Col className='p-3' sm={12} md={6}>
      <img src={img} alt='' className='img-fluid' />

          </Col>
          <Col className='p-3' sm={12} md={6}>
            
      <img src={img1} alt='' className='img-fluid'/>
          </Col>
        </Row>
      </div>
      </Container>
    
  )
}
