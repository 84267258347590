import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import homelogo from '../../assets/ihub-logo-header.svg';
import { Link, NavLink, useLocation } from 'react-router-dom';
import './navbar.css';

const Topbar = () => {
  const location = useLocation();
  const isDropdownActive = location.pathname.startsWith('/hub-');

  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const handleNavToggle = () => {
    setIsNavExpanded(!isNavExpanded);
  };
  
  return (
    <Navbar expand="lg" className="top-bar-bg" sticky="top" onToggle={handleNavToggle} expanded={isNavExpanded}>
      <Container>
        <Navbar.Brand as={Link} to="/"><img src={homelogo} alt="home-logo" className='img-fluid w-75 py-2 top-logo'/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto nav-spc">
            {/* <Link className='nav-link' to="/">Home</Link> */}
            <NavLink to="/" className='nav-link' activeclassname='active-link' onClick={() => setIsNavExpanded(false)}>
              Home
            </NavLink>
            {/* Organisation dropdown */}
            <NavDropdown title="About Us" id="basic-nav-dropdown1" active={isDropdownActive} onSelect={() => setIsNavExpanded(false)}>
                <NavDropdown.Item as={Link} to="/hub-gov-board-team" className={`dropdown-item ${location.pathname === '/hub-gov-board-team' ? 'active-link' : ''}`}>Hub Governing Board</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/hub-team" className={`dropdown-item ${location.pathname === '/hub-team' ? 'active-link' : ''}`}>Team</NavDropdown.Item>
                
                {/* <NavDropdown.Item as={Link} to="/" className={`dropdown-item ${location.pathname === '/hub-focus-area' ? 'active-link' : ''}`}>IIIT-Hyd & NM-ICPS</NavDropdown.Item> */}
              </NavDropdown>
              {/* <NavLink as={Link} to="/events" activeclassname='active-link' onClick={() => setIsNavExpanded(false)}>Events</NavLink> */}
              {/* dropdown ends */}
              {/* Organisation dropdown */}
              {/*<NavDropdown title="Activities" id="basic-nav-dropdown3" onSelect={() => setIsNavExpanded(false)}>
                 <NavDropdown.Item as={Link} to="/#IIIT-Hyd&NM-ICPS" >Focus Areas</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/events">Events</NavDropdown.Item>
                 <NavDropdown.Item as={Link} to="/hub-gov-team" className={`dropdown-item ${location.pathname === '/hub-gov-team' ? 'active-link' : ''}`}>Hub Board Team</NavDropdown.Item> 
              </NavDropdown>*/}
              {/* dropdown ends */}
            
            <NavLink to="/startups" className='nav-link' activeclassname='active-link' onClick={() => setIsNavExpanded(false)}>Startups</NavLink>
            <NavLink to="/events" className='nav-link' activeclassname='active-link' onClick={() => setIsNavExpanded(false)}>Events</NavLink>
            <NavLink to="/blogs" className='nav-link' activeclassname='active-link' onClick={() => setIsNavExpanded(false)}>Blogs</NavLink>
            <NavLink to="/tenders" className='nav-link' activeclassname='active-link' onClick={() => setIsNavExpanded(false)}>Tenders</NavLink>
            <NavLink to="/careers" className='nav-link' activeclassname='active-link' onClick={() => setIsNavExpanded(false)}>Careers</NavLink>
            <NavLink to="/contact-us" className='nav-link' activeclassname='active-link' onClick={() => setIsNavExpanded(false)}>Contact Us</NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Topbar;