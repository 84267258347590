import React from "react";
import MainSubHeading from "../../Sub-Heading/MainSubHeading";
import { Container, Row, Col } from "react-bootstrap";
import blogsbioimpedanceImg from "../../../assets/blogs/internal-blogs/blogsbioimpedancebelt.png";
export default function bioImpedanceBelt() {
  const subheadingData = "Blogs"; // You should replace this with your actual subheading data
  return (
    <>
      <Container>
        <MainSubHeading subheading={subheadingData} />
        <div className="mt-4">
          <h1 className="text-center">
            Affordable Non-Invasive Bio-impedance Belt: A Lifesaver for Heart
            Failure Patients
          </h1>
        </div>
        <Row>
          <Col className="text-center mb-4">
            <img src={blogsbioimpedanceImg} className="img-fluid w-75" />
          </Col>
        </Row>
        <p>
          A simple cost effective non-invasive wearable bioimpedance belt can be
          a boon for susceptible heart failure patients.
        </p>
        <p>
          There are about 64 million cases of heart failure worldwide as per AHA
          (American Heart Association). Heart failure is a progressive clinical
          syndrome characterized by a structural abnormality of heart, in which
          heart is unable to pump sufficient blood to meet the body’s
          requirement.
        </p>
        <p>
          Currently there are invasive as well as some non-invasive solutions to
          monitor susceptible heart failure patients. But most of these are
          quite expensive. Moreover, it is estimated half of these potential
          patient groups, don’t need an invasive solution. A non – invasive
          Bio-impedance belt to monitor fluid buildup in thoracic region could
          be a potential cost-effective solution when used in conjunction with
          other non-invasive biomonitoring parameters like electrocardiogram
          (ECG), heart rate and motion activity detection.
        </p>
        <p>
          The buildup of thoracic fluid can start days to weeks, before clinical
          symptoms such as shortness of breath, fatigue and swelling (edema)
          becomes apparent. To understand this mechanism of fluid buildup, one
          needs to deep dive into the pathophysiology of heart failure.
        </p>

        <p>
          <b>Heart Failure Pathophysiology:</b>
        </p>
        <p>
          In heart failure, the heart’s ability to pump blood effectively is
          compromised. This leads to an increased pressure in the veins and
          capillaries that return blood to the heart, particularly those in the
          lungs. As the pressure in this blood vessel increases, fluid begins to
          leak into the surrounding lung tissue, causing pulmonary congestion.
          This fluid buildup in thoracic cavity is a direct result of heart’s
          reduced pumping efficiency. Detecting these changes early, can help in
          timely intervention and management.
        </p>
        <p>
          When I say fluid buildup, it will be noteworthy to mention what this
          fluid is. The fluid mentioned here is a combination of plasma and
          interstitial fluid. The interstitial fluid are fluids that exist in
          spaces between cells in the tissues.
        </p>
        <p>
          <b>Bio-impedance Technology:</b>
        </p>
        <p>
          We can monitor this fluid buildup using a non-invasive Bio-impedance
          belt. The Bio-impedance technology is gaining prominence owing to its
          cost effectiveness and ease of use. The principle behind this is
          bioimpedance that measures the opposition to the flow of electric
          current through body tissues, which varies with the amount of fluid
          present. The electrodes placed on the body, deliver a small electric
          current and the impedance (resistance) to this current flow is
          measured. Lower impedance means, higher the fluid content. The beauty
          is this can be monitored continuously using a wearable belt in a home
          care set up.
        </p>
        <p className="fw-bold">
          What do we do if there is a presence of such fluid buildup:
        </p>
        <p>
          When fluid builds up in potential heart failure patient, prompt and
          effective treatment is essential to manage symptoms, prevent
          complications and improve the quality of life. This can be treated by
          lifestyle modification, drugs like diuretics, ACE Inhibitors, ARB’s or
          Beta Blockers type medications. Effectively this will prevent a
          certain heart failure.
        </p>
        <p>
          <b>
            What are the alternate technologies to detect early heart failure?
          </b>
        </p>
        <p>
          <b>Non-Invasive Method: </b>Dielectric sensing is a highly accurate
          method to quantify lung fluid volume. This is a technology in which
          ability to store electrical energy of lung tissue, is measured. This
          property changes with amount of fluid content, allowing the device to
          quantify the amount of fluid. But this needs a very costly
          sophisticated wearable vest. Here the high price and less
          accessibility for such a technology could be deterrent.
        </p>
        <p>
          <b>Invasive Methods: </b>There are surgically implanted devices
          available currently for heart failure monitoring
        </p>

        <ol>
          <li>
            Pulmonary Artery Pressure Monitoring devices: These are wireless
            implantable devices that are implanted in the pulmonary artery by a
            minimally invasive procedure. E.g., CardioMEMS HF System by Abbott.
          </li>
          <li>
            ICD (Implantable Cardioverter Defibrillator): These are devices
            which monitor heart rhythms and can deliver shocks to correct, life
            threatening arrythmias. E.g., Medtronic
          </li>
          <li>
            Left Atrial Pressure (LAP) Monitoring: These devices are designed to
            monitor and provide direct measurements of left atrial pressures.
            E.g., St Jude
          </li>
          <li>
            CRT (Cardiac Resynchronized Therapy) Devices: These devices in sync
            with biomonitoring devices are used to improve heart rhythms. E.g.,
            Boston Scientific
          </li>
        </ol>
        <p>
          <b>Conclusion:</b>
        </p>
        <p>
          In the above article, every effort was made to simplify the language
          used here, for everyone’s understanding. I foresee a huge potential
          for a non-invasive cost-effective Bio-impedance belt, in a country
          like India. There is a large section of potential susceptible heart
          failure patients, who are not being monitored now. At IIIT-H Data IHub
          Foundation - Hyderabad, a team led by Prof. Deva Priyakumar, Dr
          Maitreya Maity, Dr Kiron Varsha and team has developed a very cost
          effective proto of a Wearable Bioimpedance Belt. The solution was
          developed by AI/ML algorithms using enough data from Indian
          population. This technology is now ready for tech transfer. We look
          forward for an association with MNC giants or Indian medical device
          manufacturers to use this opportunity to productize it.
        </p>
        <p>
          <i>About the Author:</i>
        </p>
        <p>
          <i>
            Judish Raj has almost three decades of experience in medical devices
            & life sciences instrumentation technology. He is currently serving
            as Manager – Healthcare Innovations & Business Development at IIIT-H
            Data IHub Foundation - Hyderabad
          </i>
        </p>
      </Container>
    </>
  );
}
