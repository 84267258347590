import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./faculty.css";
import PJ from "../../assets/faculty/NarayananPJ.webp";
import Ekta from "../../assets/faculty/ekta-1.webp";
import Ravindran from "../../assets/faculty/ravindran1.webp";
import Ramesh from "../../assets/faculty/ramesh.webp";
import CVJ from "../../assets/faculty/CVJ.webp";
import Deva from "../../assets/faculty/deva.webp";
import MainSubHeading from "../Sub-Heading/MainSubHeading";
import hello from "../../assets/faculty/dummy img.webp";
import Jay from "../../assets/faculty/Jay.webp";
import venkat from "../../assets/faculty/Venkat.webp";
import Rajgopal from "../../assets/faculty/Rajgopal.webp";

export default function Faculty() {
  const subheadingData = "Hub Governing Board";
  return (
    <>
      <Container>
        <MainSubHeading subheading={subheadingData} />
        {/* faculty member profile start */}
        <Row>
          <Col xs={12} md={6} className="p-4">
            <div className="faculty-bx">
              <Row className="m-0 cus_hub_team_row">
                <Col xs={12} sm={12} md={5} lg={4} className="p-sm-2 p-md-4">
                  <img src={PJ} alt="" className="img-fluid rounded-4" />
                </Col>
                <Col xs={12} sm={12} md={7} lg={8} className="p-2 pt-0 pt-md-4">
                  <h4 className="faculty-main-head fw-bold">P.J. Narayanan</h4>
                  <h6 className="faculty-sub-head">
                    Professor & Director Ph.D (University of Maryland, USA)
                    <br />
                    IIITH, Chair
                  </h6>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={12} md={6} className="p-4">
            <div className="faculty-bx">
              <Row className="m-0 cus_hub_team_row">
                <Col xs={12} sm={12} md={5} lg={4} className="p-sm-2 p-md-4">
                  <img src={Ekta} alt="" className="img-fluid rounded-4" />
                </Col>
                <Col xs={12} sm={12} md={7} lg={8} className="p-2 pt-0 pt-md-4">
                  <h4 className="faculty-main-head fw-bold">Dr. Ekta Kapoor</h4>
                  <h6 className="faculty-sub-head">Mission Director, DST</h6>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={12} md={6} className="p-4">
            <div className="faculty-bx">
              <Row className="m-0 cus_hub_team_row">
                <Col xs={12} sm={12} md={5} lg={4} className="p-sm-2 p-md-4">
                  <img src={Ravindran} alt="" className="img-fluid rounded-4" />
                </Col>
                <Col xs={12} sm={12} md={7} lg={8} className="p-2 pt-0 pt-md-4">
                  <h4 className="faculty-main-head fw-bold">B. Ravindran</h4>
                  <h6 className="faculty-sub-head">
                    Member, IIT Madras <br />
                    Head, Robert Bosch Centre for Data Science and AI Professor
                  </h6>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={12} md={6} className="p-4">
            <div className="faculty-bx">
              <Row className="m-0 cus_hub_team_row">
                <Col xs={12} sm={12} md={5} lg={4} className="p-sm-2 p-md-4">
                  <img src={venkat} alt="" className="img-fluid rounded-4" />
                </Col>
                <Col xs={12} sm={12} md={7} lg={8} className="p-2 pt-0 pt-md-4">
                  <h4 className="faculty-main-head fw-bold">Venkat Padmanabhan</h4>
                  <h6 className="faculty-sub-head">
                  Deputy Managing Director, Microsoft Research, India
                  </h6>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={12} md={6} className="p-4">
            <div className="faculty-bx">
              <Row className="m-0 cus_hub_team_row">
                <Col xs={12} sm={12} md={5} lg={4} className="p-sm-2 p-md-4">
                  <img src={Rajgopal} alt="" className="img-fluid rounded-4" />
                </Col>
                <Col xs={12} sm={12} md={7} lg={8} className="p-2 pt-0 pt-md-4">
                  <h4 className="faculty-main-head fw-bold">Rajgopal Srinivasan</h4>
                  <h6 className="faculty-sub-head">
                  Distinguished Chief Scientist, TCS
                  </h6>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={12} md={6} className="p-4">
            <div className="faculty-bx">
              <Row className="m-0 cus_hub_team_row">
                <Col xs={12} sm={12} md={5} lg={4} className="p-sm-2 p-md-4">
                  <img src={CVJ} alt="" className="img-fluid rounded-4" />
                </Col>
                <Col xs={12} sm={12} md={7} lg={8} className="p-2 pt-0 pt-md-4">
                  <h4 className="faculty-main-head fw-bold">C.V Jawahar</h4>
                  <h6 className="faculty-sub-head">
                    Member, IIIT Hyderabad
                    <br />
                    Professor, Dean (RnD) & Amazon Chair Professor Ph.D (IIT,
                    Kharagpur)
                  </h6>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={12} md={6} className="p-4">
            <div className="faculty-bx">
              <Row className="m-0 cus_hub_team_row">
                <Col xs={12} sm={12} md={5} lg={4} className="p-sm-2 p-md-4">
                  <img src={Ramesh} alt="" className="img-fluid rounded-4" />
                </Col>
                <Col xs={12} sm={12} md={7} lg={8} className="p-2 pt-0 pt-md-4">
                  <h4 className="faculty-main-head fw-bold">
                    Ramesh Loganathan
                  </h4>
                  <h6 className="faculty-sub-head">
                    Member, IIIT Hyderabad
                    <br />
                    Professor of Practice, Co-innovations M.E (Anna University,
                    Guindy)
                  </h6>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={12} md={6} className="p-4">
            <div className="faculty-bx">
              <Row className="m-0 cus_hub_team_row">
                <Col xs={12} sm={12} md={5} lg={4} className="p-sm-2 p-md-4">
                  <img src={Deva} alt="" className="img-fluid rounded-4" />
                </Col>
                <Col xs={12} sm={12} md={7} lg={8} className="p-2 pt-0 pt-md-4">
                  <h4 className="faculty-main-head fw-bold">
                    U. Deva Priyakumar
                  </h4>
                  <h6 className="faculty-sub-head">
                    Academic Head, IHub-Data, Member Secretary
                    <br />
                    Professor Ph.D (Pondicherry University)
                  </h6>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={12} md={6} className="p-4">
            <div className="faculty-bx">
              <Row className="m-0 cus_hub_team_row">
                <Col xs={12} sm={12} md={5} lg={4} className="p-sm-2 p-md-4">
                  <img src={Jay} alt="" className="img-fluid rounded-4" />
                </Col>
                <Col xs={12} sm={12} md={7} lg={8} className="p-2 pt-0 pt-md-4">
                  <h4 className="faculty-main-head fw-bold">
                  Jay Mookherje
                  </h4>
                  <h6 className="faculty-sub-head">
                  CEO, IHub - Data, PhD (Florida International University, USA)
                  </h6>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        {/* faculty member profile ends */}
      </Container>
    </>
  );
}
