import React from "react";
import Banner from "./Banner/Banner";
import AboutUs from "./AboutUs/AboutUs";
import OurAchievements from "./OurAchievements/OurAchievements";
import Events from "./Events/Events";
import { FocusArea } from "./FocusArea/FocusArea";

export default function Home() {
  return (
    <>
      {/* Banner Component */}
      <Banner />
      {/* home page About Us section */}
      <AboutUs />
      {/* Our Achievements section */}
      <OurAchievements />
      {/* home event slider */}
      <Events />
      {/* Focus Areas */}
      <FocusArea />
    </>
  );
}
