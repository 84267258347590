import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import img from "../../../assets/events/internal Events Images/bio 24/img1.webp"
import img1 from "../../../assets/events/internal Events Images/bio 24/img2.webp"
import img2 from "../../../assets/events/internal Events Images/bio 24/img3.webp"

import MainSubHeading from "../../Sub-Heading/MainSubHeading";
export default function eventOne() {
  const subheadingData = "Events";
  return (
    
    <Container>
      <MainSubHeading subheading={subheadingData} />
      <div className="mt-4">

      <h1 className='text-center'>IHub-Data team participated in BioAsia 2024</h1>

      <p>
      The BioAsia 2024 was hosted from February 26-28, 2024 in Hyderabad. This year the theme focused on "Data and AI: Redefining Possibilities." With a legacy of 20 successful years, BioAsia is Asia's largest life sciences & health-tech conference, attracting global leaders, Nobel Prize winners, and Ministerial delegations.
      </p>
      <p>
      The event was even more significant this year as it marked the launch of the World Economic Forum’s Centre for Fourth Industrial Revolution in India, offering a unique opportunity for global collaboration. Over 2000 leaders, startups, and entrepreneurs from 50 countries participated in activities like business partnering, exhibitions, technology conferences, and CEO Conclave. The "Innovation Zone" featured the Startup Stage Pavilion and Incubator Pavilion, highlighting promising startups and innovation ecosystems.
      </p>
      <p>
      From IHub-Data a team led by CTO Veera Ganesh Yalla showcased our latest projects and research posters at our stall.
      </p>
      </div>
      <div className="img-content text-center">
      <Row>
          <Col className='p-3' sm={12} md={6}>
            <img src={img} alt='' className='img-fluid' />
          </Col>
          <Col className='p-3' sm={12} md={6}>
            
            <img src={img1} alt='' className='img-fluid'/>
          </Col>
          <Col className='p-3' sm={12} md={6}>
            <img src={img2} alt='' className='img-fluid'/>
          </Col>
        </Row>
      </div>
      </Container>
    
  )
}
