import React from 'react'
import ihub from "../../../assets/logos/footer/ihub.svg"
import dst from "../../../assets/logos/footer/dst.svg"
import nmicps from "../../../assets/logos/footer/nmicps.svg"
import iiith from "../../../assets/logos/footer/iiith.svg"
import cie from "../../../assets/logos/footer/cie.svg"
import { Col, Row } from 'react-bootstrap'

export default function Collaborate() {
  return (
        <Row className='py-3 bg-white text-center justify-content-center align-items-center'>
            {/* <Col md={1}>
            </Col> */}
            <Col md={2} sm={6} xs={6} lg={2}>
            <img src={ihub} className='img-fluid' />
            </Col>
            
            <Col md={2} sm={6} xs={6} lg={2}>
            <img src={dst} className='img-fluid' />
            </Col>
            
            <Col md={2} sm={6} xs={6} lg={2}>
            <img src={nmicps} className='img-fluid' />
            </Col>
            
            <Col md={2} sm={6} xs={6} lg={2}>
            <img src={iiith} className='img-fluid' />
            </Col>
            <Col md={2} sm={6} xs={6} lg={2}>
            <img src={cie} className='img-fluid' />
            </Col>
            {/* <Col md={1}>
            </Col> */}
        </Row>
  )
}
