import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import MainSubHeading from '../Sub-Heading/MainSubHeading';
import { FaMapLocationDot } from "react-icons/fa6";
import { MdAddIcCall } from "react-icons/md";
import { IoIosMail } from "react-icons/io";
import "./contactus.css"
import axios from 'axios';
export default function ContactUs() {
  const subheadingData ="Contact Us"
  // const [userData, setuserData] = useState({
  //   userName: "",
  //   userEmail: "",
  //   userMessage: "",
  // });
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      try {
        // Post data using Axios
        await axios.post('http://10.4.25.20:3001/api/contact', formData);
        alert('Message sent successfully!');
        // Clear form data after successful submission
        setFormData({ name: '', email: '', message: '' });
      } catch (error) {
        console.error('Error sending message:', error);
        alert('An error occurred. Please try again later.');
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = (data) => {
    const errors = {};
    if (!data.name.trim()) {
      errors.name = 'Name is required';
    }
    if (!data.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Email is invalid';
    }
    if (!data.message.trim()) {
      errors.message = 'Message is required';
    }
    return errors;
  };
  return (
      <Container className=''>
        <MainSubHeading subheading={subheadingData} />
        <Row className='contact-us-main-dv'>
          <Col xs={12} md={5}>
          <h4 className='fw-bold'>For any queries:</h4>
          {/* <Row>
          </Row> */}
          {/* <ul className='list-unstyled contact-us'>
            <li><FaMapLocationDot /> IIIT Hyderabad, Professor CR Rao Rd, Gachibowli, Hyderabad, Telangana 500032</li>
            <li><MdAddIcCall /> +91 40 6653 1783</li>
            <li><IoIosMail />ihub-data@iiit.ac.in</li>
          </ul> */}
          <Row>
            <Col xs={1} md={2} className='contact-us-logo '>
            <FaMapLocationDot />
            </Col>
            <Col xs={11} md={10} className='contact-us-con mx-sm-3 mx-md-0 px-md-0'>
            <p class="px-2 pt-1">IIIT Hyderabad, Professor CR Rao Rd, Gachibowli, Hyderabad, Telangana 500032</p>
            </Col>
          </Row>
          <Row>
            <Col xs={1} md={2} className='contact-us-logo'>
            <MdAddIcCall />
            </Col>
            <Col xs={11} md={10} className='contact-us-con mx-sm-3 mx-md-0 px-md-0'>
            <p class="px-md-2 pt-1">+91 40 6653 1783</p>
            </Col>
          </Row>
          <Row>
            <Col xs={1} md={2} className='contact-us-logo'>
            <IoIosMail />
            </Col>
            <Col xs={11} md={10} className='contact-us-con mx-sm-3 mx-md-0 px-md-0'>
            <p class="px-2 pt-1">ihub-data@iiit.ac.in</p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className='d-none'>
          <iframe
            id="gmap_canvas"
            className='w-100'
            title="IIIT Hyderabad Map"
            src="https://maps.google.com/maps?q=iiit%20hyderbad&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
            />
            </Col>
          </Row>

          </Col>
          <Col md={1}></Col>
          <Col xs={12} md={5} className='d-none'>
          <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicName">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Enter your name"
          isInvalid={errors.name}
        />
        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Enter your email"
          isInvalid={errors.email}
        />
        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Write your message</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Enter your message"
          isInvalid={errors.message}
        />
        <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
      </Form.Group>
      <Button variant="danger" type="submit">
        Submit
      </Button>
    </Form>
          </Col>
          <Col md={5} xs={12}>
          <Row>
            <Col xs={12}>
          <iframe
            id="gmap_canvas"
            className='w-100'
            title="IIIT Hyderabad Map"
            src="https://maps.google.com/maps?q=iiit%20hyderbad&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
            />
            </Col>
          </Row>
          </Col>
        </Row>
      </Container>
  )
}
