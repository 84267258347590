import React from 'react'
import { Container } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion';
import './tenders.css'
// import { FaPlusCircle } from "react-icons/fa";
import tenderDoc from "../../assets/pdf/CRO-NOTICE INVITING TENDER.pdf"
import tenderDoc2 from "../../assets/pdf/Tender - CROs-Updated.pdf"
import tenderDoc3 from "../../assets/pdf/Storage Server Qty 1-Extended-22-08.pdf"
import tenderDoc4 from "../../assets/pdf/BOQ-Furniture-Space-Final.pdf"
import tenderDoc5 from "../../assets/pdf/NIT-FURNITURE-SPACE-SIGN.pdf"
import tenderDoc6 from "../../assets/pdf/Drawings - Furniture - Space.pdf"
import tenderDoc7 from "../../assets/pdf/IIIT Tender Document-final Revised.pdf"
import tenderDoc8 from "../../assets/pdf/NIT Extended.pdf"
import tenderDoc9 from "../../assets/pdf/WEB-APP SERVER-2qty-Upadated (1).pdf"
import tenderDoc10 from "../../assets/pdf/Letter-I hub -NIT.pdf"
import tenderDoc11 from "../../assets/pdf/I-HUB -NIT.pdf"
import MainSubHeading from '../Sub-Heading/MainSubHeading';
export default function Tenders() {
  const subheadingData = "OPEN TENDERS"
  const subheadingData1 = "Closed TENDERS"
  return (
    <>
    <Container>
        {/* <div className="section-title my-3">
          <h2 className="sub-heading">IHUB-DATA</h2>
          <p className="my-3">OPEN TENDERS</p>
        </div> */}
        {/* #### For Open Tenders */}

        {/* <MainSubHeading subheading={subheadingData} /> */}
      {/* accordian start */}
      {/* <div className="d-flex justify-content-center">
      
      <Accordion className='w-75'>
      <Accordion.Item eventKey="0">
        <Accordion.Header>CRO (Contract Research Organization) Service</Accordion.Header>
        <Accordion.Body>
          <p>
          Inviting Quotations for CRO (Contract Research Organization) Service of Measurement of binding affinity of 100 protein-ligand complexes, IIIT-H DATA IHUB FOUNDATION, International Institute of Information Technology, Hyderabad.  
          </p>
          <p><span className='d-flex'><span className='fw-bold'>Deadline: </span>On or Before 13 Jan 2024 by 3pm.</span></p>
          <p><a href={tenderDoc} rel="noreferrer" target='_blank'>View Tender Document</a></p>
          <p><a href={tenderDoc2} rel="noreferrer" target='_blank'>View Tender Notice</a></p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div> */}
      {/* accordian ends */}

      {/* #####
            For Closed Tenders
      #### */}

  <MainSubHeading subheading={subheadingData1} />
        {/* accordian start */}
    
      <div className="d-flex justify-content-center">

      <Accordion className='w-75'>
      <Accordion.Item eventKey="0">
        <Accordion.Header>CRO (Contract Research Organization) Service</Accordion.Header>
        <Accordion.Body>
          <p>
          Inviting Quotations for CRO (Contract Research Organization) Service of Measurement of binding affinity of 100 protein-ligand complexes, IIIT-H DATA IHUB FOUNDATION, International Institute of Information Technology, Hyderabad.  
          </p>
          <p><span className='d-flex'><span className='fw-bold'>Deadline: </span>On or Before 13 Jan 2024 by 3pm.</span></p>
          <p><a href={tenderDoc} rel="noreferrer" target='_blank'>View Tender Document</a></p>
          <p><a href={tenderDoc2} rel="noreferrer" target='_blank'>View Tender Notice</a></p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Storage Server</Accordion.Header>
        <Accordion.Body>
          <p>
          Inviting Quotations for Storage Server – 1 Qty for IIIT-H DATA IHUB FOUNDATION, International Institute of Information Technology Hyderabad.
          </p>
          <p><span className='d-flex'><span className='fw-bold'>Deadline: </span>On or Before 22nd August 2023 by 3pm.</span></p>
          <p><a href={tenderDoc3} rel="noreferrer" target='_blank'>View Tender Document</a></p>
          {/* <p><a href={tenderDoc2} rel="noreferrer" target='_blank'>View Tender Notice</a></p> */}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>IHUB-Data – Space Development works for in Modular Furniture</Accordion.Header>
        <Accordion.Body>
        <p>
          Inviting Quotations for IHUB-Data – Space Development works for in Modular Furniture IHUBData in 3rd floor Nilgiri & B3 - Second floor, Vindhya Bhavan in IIIT-H campus, Premises of International Institute of Information Technology, Gachibowli, Hyderabad - 500032 /2022-23/3
        </p>
        <p><span className='d-flex'><span className='fw-bold'>Deadline: </span>On or Before 19th June 23, by 12pm.</span></p>
        <p><a href={tenderDoc4} rel="noreferrer" target='_blank'>View Tender Document</a></p>
        <p><a href={tenderDoc5} rel="noreferrer" target='_blank'>View Tender Notice</a></p>
        <p><a href={tenderDoc6} rel="noreferrer" target='_blank'>View Drawings of Furniture Space</a></p>
        
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Space Development works for IHUB-Data</Accordion.Header>
        <Accordion.Body>
        <p>
        Inviting Quotations for Space Development works for IHUB-Data in 3rd floor Nilgiri & B3-Second floor, Vindhya Bhavan in IIIT-H campus, Premises of International Institute of Information Technology, Gachibowli, Hyderabad - 500 032.
        </p>
        <p><span className='d-flex'><span className='fw-bold'>Deadline: </span>on or before 23rd March 2023, by 3 pm.</span></p>
        <p><a href={tenderDoc7} rel="noreferrer" target='_blank'>View Tender Document</a></p>
        <p><a href={tenderDoc8} rel="noreferrer" target='_blank'>View Tender Notice</a></p>
        {/* <p><a href={tenderDoc9} rel="noreferrer" target='_blank'>View Drawings of Furniture Space</a></p> */}
        
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Web/Application Servers</Accordion.Header>
        <Accordion.Body>
        <p>
          Notice Inviting Tender (NIT) under Two-Cover Bid System For supply of Web/Application Servers – 2 Qty for IIIT-H DATA IHUB FOUNDATION, International Institute of Information Technology Hyderabad - 500032.
        </p>
        <p><span className='d-flex'><span className='fw-bold'>Deadline: </span>on or before 15 Dec 2022, by 3pm.</span></p>
        <p><a href={tenderDoc9} rel="noreferrer" target='_blank'>View Tender Document</a></p>
        {/* <p><a href={tenderDoc8} rel="noreferrer" target='_blank'>View Tender Notice</a></p> */}
        {/* <p><a href={tenderDoc9} rel="noreferrer" target='_blank'>View Drawings of Furniture Space</a></p> */}
        
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>Space Development Works in l-Hub Data</Accordion.Header>
        <Accordion.Body>
        <p>
          Inviting Quotations for Space development works in l-hub Data in block-B3, Second floor, Vindhya & 3rd Nilgiri in the premises of lllT-H, Gachibowli, Hyderabad- 500032.
        </p>
        <p><span className='d-flex'><span className='fw-bold'>Deadline: </span>on or before 30 July 2022, by 3pm.</span></p>
        <p><a href={tenderDoc11} rel="noreferrer" target='_blank'>View Tender Document</a></p>
        <p><a href={tenderDoc10} rel="noreferrer" target='_blank'>View Tender Notice</a></p>
       
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
    </div>
        </Container>
    </>
  )
}
