import devaPriyakumar from "../../assets/faculty/team faculty/2.webp";
import sVerma from "../../assets/faculty/s verma.webp";
import CVJ from "../../assets/faculty/CVJ.webp";
import rameshLoganathan from "../../assets/faculty/ramesh.webp";
import vikramPudi from "../../assets/faculty/team faculty/1.webp";
import bapiRaju from "../../assets/faculty/team faculty/4.webp";
import CKRaju from "../../assets/faculty/team faculty/3.webp";
import gopalaKrishnan from "../../assets/faculty/team faculty/5.webp";
import Ashutosh from "../../assets/faculty/team faculty/AshutoshSir.webp";
import Ganesh from "../../assets/faculty/team faculty/GaneshSir.webp";
import hello from "../../assets/faculty/dummy img.webp";
import Jay from "../../assets/faculty/Jay.webp";

const facData = [
    {
        "img": devaPriyakumar,
        "name": "U. Deva Priyakumar",
        "details": "Academic Head, IHub-Data, Member Secretary, Professor Ph.D (Pondicherry University)"
    },
    {
        "img": Jay,
        "name": "Jay Mookherje",
        "details": "CEO, IHub - Data, PhD (Florida International University, USA)"
    },
    {
        "img": Ashutosh,
        "name": "Ashutosh Kumar",
        "details": "COO, IHub - Data"
    },
    {
        "img": Ganesh,
        "name": "Veera Ganesh Yalla",
        "details": "CTO, IHub - Data"
    },
    // {
    //     "img": sVerma,
    //     "name": "Varma S. Konala",
    //     "details": "CEO, INAI"
    // },
    {
        "img": bapiRaju,
        "name": "Bapi Raju",
        "details": "Head Healthcare"
    },
    {
        "img": CVJ,
        "name": "C.V. Jawahar",
        "details": "Head Mobility"
    },
    {
        "img": vikramPudi,
        "name": "Vikram Pudi",
        "details": "Head Data Foundation"
    },
    {
        "img": rameshLoganathan,
        "name": "Ramesh Loganathan",
        "details": "Head Startups & Innovation, Professor of Practice, Co-innovations M.E (Anna University, Guindy)"
    },
    {
        "img": CKRaju,
        "name": "C.K. Raju",
        "details": "Head Programs"
    },
    {
        "img": gopalaKrishnan,
        "name": "B Gopalakrishnan",
        "details": "Scientific Operations"
    }
];

export default facData;
