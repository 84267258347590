import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import img from "../../../assets/events/internal Events Images/sysnops-1.webp"
import img1 from "../../../assets/events/internal Events Images/sysnops-2.webp"
import MainSubHeading from "../../Sub-Heading/MainSubHeading";
export default function eventOne() {
  const subheadingData = "Events";
  return (
    
    <Container>
      <MainSubHeading subheading={subheadingData} />
      <div className="mt-4">

      <h1 className='text-center'>Symposium on Simulator Platform for Safe Driving in Indian Road Conditions</h1>

      <p>
        The challenges of driving on Indian roads in unique and to improve driver safety in Indian Road Conditions, we hosted a symposium on Mobility Simulator Program at IIIT-Hyderabad on December 21 and 22, 2023. Experts came together to analyze driver behavior, enhance driver training, and facilitate cutting-edge research in transportation, psychology, engineering safety, and new technology development, at the symposium.
      </p>
      <p>
        Simulator Symposium featured 18 speakers, with 11 representing academia (UCSD, AI2/UW, UCLA, Texas Robotics, IITB, IITH, IITR, IITD) and 7 from industry (Google, NVIDIA, TCS Research, Monarch Tractor, CSIR-CRRI, Ola Electric, Enphiniti). The event drew 54 external participants, with 37 from academia and 17 from industry.
      </p>
      
      </div>
      <div className="img-content text-center">
      <Row>
          <Col className='p-3' sm={12} md={6}>
      <img src={img} alt='' className='img-fluid' />

          </Col>
          <Col className='p-3' sm={12} md={6}>
            
      <img src={img1} alt='' className='img-fluid'/>
          </Col>
        </Row>
      </div>
      </Container>
    
  )
}
