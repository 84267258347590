import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./achievment.css";
import img1 from "../../../assets/logos/papers.svg";
import img2 from "../../../assets/logos/patents.svg";
import img3 from "../../../assets/logos/fellowships.svg";
import img4 from "../../../assets/logos/prod.svg";
// import img5 from "../../../assets/logos/papers.png";
import img6 from "../../../assets/logos/skilldev.svg";
import img7 from "../../../assets/logos/research-and-development.svg";
import img8 from "../../../assets/logos/startup.svg";
import img9 from "../../../assets/logos/collaborat.svg"
export default function OurAchievements() {
  return (
    <>
      <Container className="my-md-4 py-md-3 my-sm-1 py-sm-1" fluid>
        <h2 className="fw-bold text-center py-3">Our Achievements</h2>
        <Row className="justify-content-center text-center mt-4 py-1">
          <Col xs={6} sm={4} md={3} lg={3}>
            <div className="card-main">
              <div className="card-img">
                <img src={img1} className="img-fluid" alt="Publications-logo" />
              </div>
              <h4>147</h4>
              <p>Publications</p>
            </div>
          </Col>
          <Col xs={6} sm={4} md={3} lg={3}>
            <div className="card-main">
              <div className="card-img">
                <img src={img2} className="img-fluid" alt="Patents-logo" />
              </div>
              <h4>10</h4>
              <p>Patents</p>
            </div>
          </Col>
          <Col xs={6} sm={4} md={3} lg={3}>
            <div className="card-main">
              <div className="card-img">
                <img src={img3} className="img-fluid" alt="Fellowships-logo" />
              </div>
              <h4>355</h4>
              <p>Fellowships</p>
            </div>
          </Col>
          <Col xs={6} sm={4} md={3} lg={3}>
            <div className="card-main">
              <div className="card-img">
                <img
                  src={img4}
                  className="img-fluid"
                  alt="Tech-Products-logo"
                />
              </div>
              <h4>18</h4>
              <p>Tech Products</p> {/* Technology Platform */}
            </div>
          </Col>
          </Row>
          <Row className="justify-content-center text-center mt-4 py-4">
          <Col xs={6} sm={4} md={3} lg={3}>
            <div className="card-main">
              <div className="card-img">
                <img src={img6} className="img-fluid" alt="Skill-Dev-logo" />
              </div>
              <h4>4935</h4>
              <p>Skill Development</p>
            </div>
          </Col>
          <Col xs={6} sm={4} md={3} lg={3}>
            <div className="card-main">
              <div className="card-img">
                <img src={img7} className="img-fluid" alt="R&D-logo" />
              </div>
              <h4>38</h4>
              <p>R&D Projects</p>
            </div>
          </Col>
          <Col xs={6} sm={4} md={3} lg={3}>
            <div className="card-main">
              <div className="card-img">
                <img src={img8} className="img-fluid" alt="Startups-logo" />
              </div>
              <h4>29</h4>
              <p>Startups</p>
            </div>
          </Col>
          <Col xs={6} sm={4} md={3} lg={3}>
            <div className="card-main">
              <div className="card-img">
                <img src={img9} className="img-fluid" alt="Revenew-logo" />
              </div>
              <h4>3</h4>
              <p>Collaborators</p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
