import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import MainSubHeading from '../Sub-Heading/MainSubHeading.jsx'
import blog1 from "../../assets/blogs/blog 1.png";
import blog2 from "../../assets/blogs/blog 2.png";
import blog3 from "../../assets/blogs/blog 3.png";
import biointerm from "../../assets/blogs/biointerm.png";
import diyPDF from "../../assets/pdf/blogs pdf/DIY Neuroscience Workshop.pdf"
export default function Blogs() {
  // const headingData = "IHUB-DATA 1";
const subheadingData = "Blogs"; // You should replace this with your actual subheading data
  return (
    <>
    <Container>
      {/* Page Heading Components */}
    <MainSubHeading subheading={subheadingData} />
    <Row>
      <Col xs={12} md={6} lg={4}>
            <div className="p-3">
              <div className="card-int">
                <img src={biointerm} alt="" className="img-fluid w-100" />
                <h6>
                Non-Invasive Bio-impedance Belt For Heart Failure Patients
                </h6>
                <p>
                A simple cost effective non-invasive wearable bioimpedance belt can be
                a boon for susceptible heart failure patients. There are about 64 million cases...
                </p>
                <div className="red-mr-btn">
                  <Button
                    className="border-0 home-btn-rd-more"
                    variant="primary" href="/blogs/bio-impedance-belt" target='_blank'
                  >
                    Read More
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <div className="p-3">
              <div className="card-int">
                <img src={blog1} alt="" className="img-fluid w-100" />
                <h6>
                DIY Neuroscience: BCI/HCI Workshop 2023
                </h6>
                <p>
                Dr. Vishnu Sreekumar (and the Cognitive Science Lab) organized a DIY Neuroscience:
                    BCI/HCI Workshop 2023 with the support of IHub-Data...
                </p>
                <div className="red-mr-btn">
                  <Button
                    className="border-0 home-btn-rd-more"
                    variant="primary" href={diyPDF} target='_blank'
                  >
                    Read More
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <div className="p-3">
              <div className="card-int">
                <img src={blog2} alt="" className="img-fluid w-100" />
                <h6>
                AI Tool for Diabetes and Hypertension
                </h6>
                <p>
                IIITH researchers in collaboration with WISH Foundation are developing an automated web-based
                    browser that will take critical healthcare...
                </p>
                <div className="red-mr-btn">
                  <Button
                    className="border-0 home-btn-rd-more"
                    variant="primary" target='_blank' href='https://blogs.iiit.ac.in/monthly_news/ai-tool-for-diabetes-and-hypertension-to-determine-continued-home-based-care-or-shift-to-a-medical-facility/'
                  >
                    Read More
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <div className="p-3">
              <div className="card-int">
                <img src={blog3} alt="" className="img-fluid w-100" />
                <h6>
                IIITH To Develop Early Oral Cancer
                      Screening Tool
                </h6>
                <p>
                The automated solution, arising out of a collaborative effort with Grace Cancer Foundation and
                    BioCon Foundation, requires just a smart phone...
                </p>
                <div className="red-mr-btn">
                  <Button
                    className="border-0 home-btn-rd-more"
                    variant="primary" target='_blank' href='https://blogs.iiit.ac.in/oral-cancer/'
                  >
                    Read More
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          
          </Row>
    </Container>
    </>
  )
}
