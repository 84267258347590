import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./aboutus.css";
import { BiCheckDouble } from "react-icons/bi";
import visionimg from "../../../assets/vision image.png";
import missionimg from "../../../assets/mission image.png";
export default function AboutUs() {
  return (
    <>
      <Container className="py-4" id="IIIT-Hyd&NM-ICPS">
        <div className="my-3">
          {/* <h2 className="sub-heading">IHUB-DATA</h2> */}
          <h2 className="fw-bold text-center py-3">
            IHUB-DATA : IIIT-H & NM-ICPS
          </h2>
        </div>
        <Row>
          <Col className="about_us_para" xs={12} md={6}>
            
            <p>
              IHub-Data has been created as a Section 8 company that manages the
              Data Foundation, Research & Technology Development, Applied
              Research & Translation, HRD & Skill Development, Innovation &
              Start-ups, and the International Collaborations directly.
            </p>
            <p>
              The Hub helps coordinate, integrate, and amplify basic and applied
              research in broad Data-Driven Technologies as well as its
              dissemination and translation across the country.
            </p>
            <p>
              The Hub will also have an intense emphasis on collaborating with
              international experts in associated areas. This will include joint
              research, visiting schemes for international experts, involvement
              in the processes and problems of the Hub, etc. A series of
              workshops, conferences, and meetings are also planned to bring
              global experts in direct contact with the Indian researchers and
              students in associated areas.
            </p>
          </Col>
          <Col xs={12} md={6}>
            {/* <img src="" alt="about-us-img" /> */}
            <p>The six major activities promoted by iHub-Data include:</p>
            <ul className="list-unstyled">
              <li>
                <BiCheckDouble
                  style={{
                    color: "orange",
                    fontSize: "26px",
                    paddingRight: "6px",
                  }}
                />
                Technology Development
              </li>
              <li>
                <BiCheckDouble
                  style={{
                    color: "orange",
                    fontSize: "26px",
                    paddingRight: "6px",
                  }}
                />
                Data Foundation
              </li>
              <li>
                <BiCheckDouble
                  style={{
                    color: "orange",
                    fontSize: "26px",
                    paddingRight: "6px",
                  }}
                />
                Applied Research & Translation
              </li>
              <li>
                <BiCheckDouble
                  style={{
                    color: "orange",
                    fontSize: "26px",
                    paddingRight: "6px",
                  }}
                />
                Human Resource & Skill Development
              </li>
              <li>
                <BiCheckDouble
                  style={{
                    color: "orange",
                    fontSize: "26px",
                    paddingRight: "6px",
                  }}
                />
                Innovation, Entrepreneurship & Startup Ecosystem
              </li>
              <li>
                <BiCheckDouble
                  style={{
                    color: "orange",
                    fontSize: "26px",
                    paddingRight: "6px",
                  }}
                />
                International Collaboration
              </li>
            </ul>
          </Col>
        </Row>

        <div className="my-3">
          {/* <h2 className="sub-heading">IHUB-DATA</h2> */}
          <h2 className="fw-bold text-center">IHUB-DATA : Vision & Mission</h2>
        </div>
        <Row className="">
          <Col className="about_us_para" xs={12} md={9}>
            <p>
              
              To be a global leader in research outcomes that positively impact
              society through technologies translated into local industries and
              governmental agencies. To play a central role in the advancement
              and adoption of data-driven technologies through proactive
              strategies in data bank curation and service creation. To become
              the pre-eminent reference for datasets for AI researchers
              worldwide and develop solutions using these datasets to address
              population-scale challenges, resulting in significant societal
              impact.
            </p>
          </Col>
          <Col className="about_us_para" xs={12} md={3}>
            <img src={visionimg} alt="" className="img-fluid" />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="about_us_para d-sm-none d-md-block" xs={12} md={3}>
            <img src={missionimg} alt="" className="img-fluid" />
          </Col>
          <Col className="about_us_para" xs={12} md={9}>
            <p>
              IHub-Data will help coordinate and enhance national research and
              solution development efforts in the Data (Banks, Services and
              Analytics) areas and take them to the highest global academic
              standards. Applications towards the betterment of the society
              around us will have a special focus at the Hub, along with the
              translation of technologies into viable products and promoting
              startups based on those technologies.
            </p>
            <ul
              className="list-unstyled mt-2 a"
              style={{ listStylePosition: "outside" }}
            >
              <li>
                <p className="d-flex mb-1">
                  <BiCheckDouble
                    style={{
                      color: "orange",
                      fontSize: "26px",
                    }}
                  />
                  <span className="mx-2">
                    To be a global leader in research outcomes that also impacts
                    our society with the technologies that are translated to
                    local industries and governmental agencies.
                  </span>
                </p>
              </li>
              <li>
                <p className="d-flex mb-1">
                  <BiCheckDouble
                    style={{
                      color: "orange",
                      fontSize: "26px",
                    }}
                  />
                  <span className="mx-2">
                    To play a central role in development and penetration of
                    data-driven technologies by taking a proactive strategy in
                    curating and creating data banks and data services.
                  </span>
                </p>
              </li>
              <li>
                <p className="d-flex">
                  <BiCheckDouble
                    style={{
                      color: "orange",
                      fontSize: "26px",
                    }}
                  />
                  <span className="mx-2">
                    To catalyse, nurture, and enable the growth of an ecosystem
                    with researchers, technologists, practitioners and
                    entrepreneurs in the area of data-driven solutions to the
                    local problems.
                  </span>
                </p>
              </li>
            </ul>
          </Col>
          {/* <Col className="about_us_para d-sm-block d-md-none" xs={12} md={3}>
            <img src={missionimg} alt="" className="img-fluid" />
          </Col> */}
        </Row>
      </Container>
    </>
  );
}
