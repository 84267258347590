import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import dataCenterImg from "../../../assets/focus areas/datalabs.webp";

import mobilityImg from "../../../assets/focus areas/mobility.webp";
import healthcareImg from "../../../assets/focus areas/healthcare.webp";
import programsImg from "../../../assets/focus areas/programs.webp";
import translationImg from "../../../assets/focus areas/translation.webp";
import startupImg from "../../../assets/focus areas/startup.webp";
import { Link } from 'react-router-dom';
import "./focusarea.css";
import { FaArrowRightLong } from "react-icons/fa6";
export const FocusArea = () => {
  return (
    <>
      <Container>
        <h2 className="fw-bold text-center mt-5 py-2"> Focus Areas </h2>
        <Row className="justify-content-center">
          <Col xs={12} md={6} lg={4}>
            <div className="card-sld1">
              <img src={dataCenterImg} className="img-fluid focusAreaCardImage" alt=""/>

              <div className="card-bottom-content position-relative ">
                <Row className="justify-content-center align-items-center">
                  <Col
                    xs={10}
                    className="d-flex justify-content-between align-items-center p-0"
                  >
                    <div className="card-con">
                      <h6 className="fw-bold">Data Foundation</h6>
                    </div>
                    <div className="rd-more">
                      <a
                        href="https://datafoundation.iiit.ac.in/"
                        target="__blank"
                        className="text-white"
                      >
                        <FaArrowRightLong style={{ fontSize: "22px" }} />
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <div className="card-sld1">
              <img src={mobilityImg} className="img-fluid focusAreaCardImage" alt=""/>

              <div className="card-bottom-content position-relative ">
                <Row className="justify-content-center align-items-center">
                  <Col
                    xs={10}
                    className="d-flex justify-content-between align-items-center p-0"
                  >
                    <div className="card-con">
                      <h6 className="fw-bold">Mobility</h6>
                    </div>
                    <div className="rd-more">
                      <a
                        href="https://mobility.iiit.ac.in/"
                        target="__blank"
                        className="text-white"
                      >
                        <FaArrowRightLong style={{ fontSize: "22px" }} />
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <div className="card-sld1">
              <img src={healthcareImg} className="img-fluid focusAreaCardImage" alt=""/>

              <div className="card-bottom-content position-relative ">
                <Row className="justify-content-center align-items-center">
                  <Col
                    xs={10}
                    className="d-flex justify-content-between align-items-center p-0"
                  >
                    <div className="card-con">
                      <h6 className="fw-bold">Healthcare</h6>
                    </div>
                    <div className="rd-more">
                      <a
                        href="https://hai.iiit.ac.in/"
                        target="__blank"
                        className="text-white"
                      >
                        <FaArrowRightLong style={{ fontSize: "22px" }} />
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} md={6} lg={4}>
            <div className="card-sld1">
              <img src={programsImg} className="img-fluid focusAreaCardImage" alt=""/>

              <div className="card-bottom-content position-relative ">
                <Row className="justify-content-center align-items-center">
                  <Col
                    xs={10}
                    className="d-flex justify-content-between align-items-center p-0"
                  >
                    <div className="card-con">
                      <h6 className="fw-bold">Programs</h6>
                    </div>
                    <div className="rd-more">
                      <a
                        href="https://ihub-data.ai/"
                        target="__blank"
                        className="text-white"
                      >
                        <FaArrowRightLong style={{ fontSize: "22px" }} />
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <div className="card-sld1">
              <img src={translationImg} className="img-fluid focusAreaCardImage" alt=""/>

              <div className="card-bottom-content position-relative ">
                <Row className="justify-content-center align-items-center">
                  <Col
                    xs={10}
                    className="d-flex justify-content-between align-items-center p-0"
                  >
                    <div className="card-con">
                      <h6 className="fw-bold">Translation</h6>
                    </div>
                    <div className="rd-more">
                      <a
                        href="https://www.inai.ai/"
                        target="__blank"
                        className="text-white"
                      >
                        <FaArrowRightLong style={{ fontSize: "22px" }} />
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <div className="card-sld1">
              <img src={startupImg} className="img-fluid focusAreaCardImage" alt=""/>

              <div className="card-bottom-content position-relative ">
                <Row className="justify-content-center align-items-center">
                  <Col
                    xs={10}
                    className="d-flex justify-content-between align-items-center p-0"
                  >
                    <div className="card-con">
                      <h6 className="fw-bold">Startups</h6>
                    </div>
                    <div className="rd-more">
                      {/* <a
                        href="https://ihub-data.iiit.ac.in/testnew/"
                        target="__blank"
                        className="text-white"
                      > */}
                      <Link className='nav-link text-white' to="/startups"><FaArrowRightLong style={{ fontSize: "22px" }} /></Link>
                        
                      {/* </a> */}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
