import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import img from "../../../assets/events/internal Events Images/DST Team Visit/dst1.webp"
import img1 from "../../../assets/events/internal Events Images/DST Team Visit/dst2.webp"
import img2 from "../../../assets/events/internal Events Images/DST Team Visit/dst3.webp"

import MainSubHeading from "../../Sub-Heading/MainSubHeading";
export default function dstVisit() {
  const subheadingData = "Events";
  return (
    
    <Container>
      <MainSubHeading subheading={subheadingData} />
      <div className="mt-4">

      <h1 className='text-center'>DST Secretary Abhay Karandikar launches IHub-Data's IDEA Lab</h1>
      <p>
      DST Secretary Prof Abhay Karandikar, NMICPS Member Secretary Dr Ekta Kapoor, NMICPS Member Convener Dr JBV Reddy visited IHub-Data at IIIT Hyderabad on March 22, 2024 and inaugurated our new IDEA Lab. The new lab that stands for Innovation, Development, Exploration and Advancement, promotes application of Hub sponsored research in the fields of healthcare, mobility and data.
      </p>
      <p>
      The Secretary met the teams of the in-house bred start-ups that displayed their apps and devices that have social impact in the fields of health and mobility like road safety, oral cancer and solutions for education in rural areas. Prof Karandikar also paid a visit to the one-of-its kind Data Center of IHub Data. The dignitaries also explored the Hub’s ties with IIIT Hyderabad’s incubator CIE.
      </p>
      <p>
        The DST leadership also met IIIT Hyderabad Director Prof PJ Narayanan, IHub Data CEO Dr Jay Mookherje, R&D Dean of IIIT Hyderabad - Prof CV Jawahar; Registrar of IIIT Hyderabad - Prof KS Rajan.
      </p>
      </div>

      <div className="img-content text-center">
        <Row>
          <Col className='p-3' sm={12} md={6}>
      <img src={img} alt='' className='img-fluid' />

          </Col>
          <Col className='p-3' sm={12} md={6}>
            
      <img src={img1} alt='' className='img-fluid'/>
          </Col>
          <Col className='p-3' sm={12} md={6}>
      <img src={img2} alt='' className='img-fluid'/>
            
          </Col>
        </Row>

      </div>
      </Container>
    
  )
}
