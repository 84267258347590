import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import img from "../../../assets/events/internal Events Images/ai-ml-program.webp"
import img1 from "../../../assets/events/internal Events Images/ai-ml-program-1.webp"
import MainSubHeading from "../../Sub-Heading/MainSubHeading";
export default function eventTwo() {
  const subheadingData = "Events";
  return (
    
    <Container>
      <MainSubHeading subheading={subheadingData} />
      <div className="mt-4">

      <h1 className='text-center'>AI/ML Executive Training Program for DRDO scientists</h1>
      
                <p>
                  IHub-Data successfully organised a two week AI/ML executive training program for DRDO scientists. The program was held in October, 2023 at the IIIT Hyderabad campus. Participants from diverse backgrounds came together to explore Artificial Intelligence and Machine Learning, and the feedback has been overwhelmingly positive.
                </p>
                <p>
                  Experts and professors from IIIT Hyderabad were among the resource persons who took classes during this program.
                </p>

      </div>
      <div className="img-content text-center">
      <Row>
          <Col className='p-3' sm={12} md={6}>
      <img src={img} alt='' className='img-fluid' />

          </Col>
          <Col className='p-3' sm={12} md={6}>
            
      <img src={img1} alt='' className='img-fluid'/>
          </Col>
        </Row>
      </div>
      </Container>
    
  )
}
