import './App.css';
// responsive CSS
import './components/Responsive/responsive.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';

import Footer from './components/Footer/Footer.jsx';
import Navbar from "./components/Navbar/Navbar.jsx";
import Home from "./components/Home/Home.jsx";
import ContactUs from './components/Contactus/ContactUs.jsx';
import Tenders from './components/Tenders/Tenders.jsx';
import Blogs from './components/Blogs/Blogs.jsx';
import Careers from './components/Careers/Careers.jsx';
import Faculty from './components/Faculty member/Faculty.jsx';
import HubTeam from './components/Faculty member/HubTeam.jsx';
import Events from './components/Events/Events.jsx';
import Startups from './components/Startups/Startups.jsx';
// import ContactUs from './components/Contactus/ContactUs.jsx';

// for events
import EventOne from './components/Events/allEvents/symposiumOnSimulatorPlatform.jsx';
import EventTwo from './components/Events/allEvents/AIMLexecutiveTraining.jsx';
import EventThree from './components/Events/allEvents/eventThree.jsx';
import BioAsia24 from './components/Events/allEvents/boiAsia2024.jsx';
import DSTTeamVisit from './components/Events/allEvents/dstTeamEvents.jsx';

// for blogs
import BioImpedanceBelt from './components/Blogs/All blogs/bioImpedanceBelt.jsx';



function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  return (
    <>
      <Router>
      <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/tenders" element={<Tenders />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/hub-gov-board-team" element={<Faculty />} />
          <Route path="/hub-team" element={<HubTeam />} />
          <Route path="/events" element={<Events />} />
          <Route path="/startups" element={<Startups />} />

          {/* Events Routing */}
          <Route path='/events/symposium-on-simulator-platform' element={<EventOne />} />
          <Route path='/events/the-ai-ml-program-hosted-by-ihub-Data' element={<EventTwo />} />
          <Route path='/events/exploring-the-intersection-of-ai-and-global' element={<EventThree />} />
          <Route path='/events/bio-asia-24' element={<BioAsia24 />} />
          <Route path='/events/dst-teams-visit' element={<DSTTeamVisit />} />
          
          {/* For Blogs */}
          <Route path='/blogs/bio-impedance-belt' element={<BioImpedanceBelt />} />
          
          {/* Redirect to Home for any other route */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;