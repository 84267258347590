import React from "react";
import ihubBanner from "../../../assets/banner/ihubBanner.webp";
import healthcare from "../../../assets/banner/healthcareBanner.webp";
import mobility from "../../../assets/banner/mobilityBanner.webp";
import programs from "../../../assets/banner/programs.webp";
import dataFoundation from "../../../assets/banner/datafoundation.webp";
import { Container } from "react-bootstrap";
import "./banner.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeft, FaArrowRight} from "react-icons/fa6";

export default function Banner() {
  
  var settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000
  };

  return (
    <>
      <Container className="p-0" fluid>
        <div className="main-div">
          <Slider {...settings}>
            <div>
              <img src={ihubBanner} className="img-fluid w-100" alt="home-banner" />
            </div>
            <div>
              <img src={healthcare} className="img-fluid w-100" alt="home-banner" />
            </div>
            <div>
              <img src={mobility} className="img-fluid w-100" alt="home-banner" />
            </div>
            <div>
              <img src={dataFoundation} className="img-fluid w-100" alt="home-banner" />
            </div>
            <div>
              <img src={programs} className="img-fluid w-100" alt="home-banner" />
            </div>
          </Slider>
        </div>
      </Container>
    </>
  );
}
