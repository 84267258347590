import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { FaSquareXTwitter  } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { IoIosArrowUp } from "react-icons/io";
// import ftr3 from "../../assets/Untitled design (13).png";
// import ftr4 from "../../assets/footercollaburationlogo.png";
import ContentLoader from 'react-content-loader'

import { Link } from 'react-router-dom';
import {
  FaTwitter,
  FaFacebook,
  FaSquareInstagram,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa6";
import "./footer.css";
import Collaborate from "./Collabrate Ihub/Collaborate";

export default function Footer() {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <Container fluid className="ftr-bg mt-md-5 pt-4 mt-4 mt-md-2">
        <Row>
          <Col xs={12} md={6} lg={4}>
            <div className="box1">
              <h5 className="text-white fw-bold">IHub-Data</h5>
              {/* <img src={Footerlogo} className='img-fluid w-md-25 ' alt='footer-logo' /> */}
              <p className="text-white">
                IHub-Data is a Technology Innovation Hub (TIH) established by
                IIIT Hyderabad as part of the National Mission on
                Interdisciplinary Cyber-Physical Systems (NM-ICPS) scheme of
                Department of Science and Technology, Govt of India.
              </p>
            </div>
          </Col>
          <Col xs={12} md={6} lg={2}>
            <div className="box2">
              <h5 className="text-white fw-bold">Quick Links</h5>
              <p className="font-white mb-1">
                
                <Link className='nav-link' to="/blogs">Blogs</Link>
              </p>
              <p className="font-white">
                
                <Link className='nav-link' to="/tenders">Tenders</Link>
              </p>
            </div>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <div className="box3">
              <h5 className="text-white fw-bold">Address</h5>
              <ul className="list-unstyled">
                <li className="text-white">
                  {/* <FaLocationDot style={{ color: "white", fontSize: "18px" }} />{" "} */}
                  IIIT Hyderabad,Professor CR Rao Rd, <br className="cus_br" />
                  Gachibowli, Hyderabad, Telangana 500032
                </li>
                <li className="text-white py-1">
                  <FaPhoneAlt style={{ color: "white", fontSize: "18px" }} />{" "}
                  <a href="tel:+914066531783">+91 40 6653 1783</a>
                </li>
                <li className="text-white">
                  <MdOutlineEmail
                    style={{ color: "white", fontSize: "18px" }}
                  />{" "}
                  <a href="mailto:ihub-data@iiit.ac.in">ihub-data@iiit.ac.in</a>
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <div className="box4">
              {/* <h5 className='text-white fw-bold'>Maps</h5> */}
              <iframe
                id="gmap_canvas"
                className="w-100"
                title="IIIT Hyderabad Map"
                src="https://maps.google.com/maps?q=iiit%20hyderbad&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
              />
            </div>
          </Col>
        </Row>
        {/* interated programs start */}
          <Collaborate />
        <Row className="">
          <Row>
            <ul className="list-unstyled social-media-link mb-0">
            <li>
                <a
                  href="https://www.linkedin.com/company/ihub-data/"
                  target="__blank"
                >
                  <FaLinkedin style={{ color: "white" }} />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/IHUB_Data" target="__blank">
                  <FaSquareXTwitter style={{ color: "white" }} />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/ihubdata/" target="__blank">
                  <FaSquareInstagram style={{ color: "white" }} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/IHub-Data-103031462109849"
                  target="__blank"
                >
                  <FaFacebook style={{ color: "white" }} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UC-EaH07Lfg1nhaHxVMgA_7w"
                  target="__blank"
                >
                  <FaYoutube style={{ color: "white" }} />
                </a>
              </li>
              
            </ul>
          </Row>
          <Col>
            <hr className="text-white w-100 fw-bold text-center" />
            <p className="text-center text-white">
              Copyright © 2024 | All rights reserved. IHub-Data, IIIT-Hyderabad
            </p>
          </Col>
        </Row>
      </Container>

      {isVisible && (
        <button
          className="back-to-top d-flex align-items-center justify-content-center fw-bold border-0"
          onClick={handleScrollToTop}
        >
          <IoIosArrowUp style={{ color: "white" }} />
        </button>
      )}
    </>
  );
}
