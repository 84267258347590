import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
// import event1 from "../../assets/events/event1.png";
import event2 from "../../assets/events/symposiumonSimulatorPlatform.png";
import event3 from "../../assets/events/theAimlProgramDRDO.png";
import event4 from "../../assets/events/exploringTheIntersectionofAI.png";
import bioasia from "../../assets/events/BioAsia 2024.png";
import dstTeam from "../../assets/events/dstTeamVisit.webp";
import datapulse from "../../assets/events/datapulse.webp";
import newsletter from "../../assets/pdf/newsletter/IHUB_DATA_IIIT-H_Q1_2024_Newsletter_updated.pdf"
import { Link } from "react-router-dom"
import MainSubHeading from "../Sub-Heading/MainSubHeading";
export default function Events() {
  const subheadingData = "Events";
  return (
    <>
      <Container>
        <MainSubHeading subheading={subheadingData} />
        <Row>
        <Col xs={12} md={6} lg={4}>
            <div className="p-3">
              <div className="card-int">
                <img src={datapulse} alt="" className="img-fluid w-100" />
                <h6>
                IHub-Data Newsletter Data Pulse <br/>
                (Jan to Mar 2024 edition)
              </h6>
              <p>
              Read about all the events and updates of IHub-Data in the first quarter of 2024 here. The highlights this quarter include 
              DST Secretary Prof Abhay Karandikar's visit....
              </p>

              <div className="red-mr-btn">
                <Button
                  className="border-0 home-btn-rd-more"
                  variant="primary"
                  target="__blank"
                  href={newsletter}
                >
                  Read More
                </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <div className="p-3">
              <div className="card-int">
                <img src={dstTeam} alt="" className="img-fluid w-100" />
                <h6>
                DST Secretary Prof Abhay Karandikar launches IHub-Data's IDEA Lab
                </h6>
                <p>
                DST Secretary Prof Abhay Karandikar, NMICPS Member Secretary Dr Ekta Kapoor, NMICPS Member Convener Dr JBV Reddy visited IHub-Data at IIIT...
                </p>
                <div className="red-mr-btn">
                  {/* <Link className='border-0 home-btn-rd-more' variant="primary" to="symposium-on-simulator-platform">Read More</Link> */}
                  
                <Button className="border-0 home-btn-rd-more" variant="primary" as={Link} to="/events/dst-teams-visit">
                  Read More
                </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <div className="p-3">
              <div className="card-int">
                <img src={bioasia} alt="" className="img-fluid w-100" />
                <h6>
                IHub-Data team participated in BioAsia 2024
                </h6>
                <p>
                The BioAsia 2024 was hosted from February 26-28, 2024 in Hyderabad. This year the theme focused on "Data and AI: Redefining Possibilities...
                </p>
                
                <div className="red-mr-btn">
                  {/* <Link className='border-0 home-btn-rd-more' variant="primary" to="symposium-on-simulator-platform">Read More</Link> */}
                  
                <Button className="border-0 home-btn-rd-more" variant="primary" as={Link} to="/events/bio-asia-24">
                  Read More
                </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <div className="p-3">
              <div className="card-int">
                <img src={event2} alt="" className="img-fluid w-100" />
                <h6>
                Symposium on Simulator Platform for Safe Driving in Indian Road Conditions
                </h6>
                <p>
                The challenges of driving on Indian roads in unique and to improve driver safety in Indian Road Conditions, we hosted a symposium on Mobility ...
                </p>
                
                <div className="red-mr-btn">
                  {/* <Link className='border-0 home-btn-rd-more' variant="primary" to="symposium-on-simulator-platform">Read More</Link> */}
                  
                <Button className="border-0 home-btn-rd-more" variant="primary" as={Link} to="/events/symposium-on-simulator-platform">
                  Read More
                </Button>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={12} md={6} lg={4}>
            <div className="p-3">
              <div className="card-int">
                <img src={event3} alt="" className="img-fluid w-100" />
                <h6>
                AI/ML Executive Training Program for DRDO scientists
                </h6>
                <p>
                  IHub-Data successfully organised a two week AI/ML executive training program for DRDO scientists. The program was held in October, 2023 at the IIIT...
                </p>
                
                <div className="red-mr-btn">
                  <Button
                    className="border-0 home-btn-rd-more"
                    variant="primary" as={Link} to="/events/the-ai-ml-program-hosted-by-ihub-Data"
                  >
                    Read More
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          
          <Col xs={12} md={6} lg={4}>
            <div className="p-3">
              <div className="card-int">
                <img src={event4} alt="" className="img-fluid w-100" />
                <h6>
                Prof P Anandanspoke on AI innovation for global health
                </h6>
                <p>
                Prof P. Anandan's talk on “Translating AI Innovations into Solutions for Global Health”, in January, explored the intersection of AI and global health...
                </p>
                <div className="red-mr-btn">
                  <Button
                    className="border-0 home-btn-rd-more"
                    variant="primary" as={Link} to="/events/exploring-the-intersection-of-ai-and-global"
                  >
                    Read More
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
