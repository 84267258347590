import React from 'react'

export default function MainSubHeading(props) {
  return (
    <>
        <div className="section-title my-3">
        <h2 className="sub-heading">IHUB-DATA</h2>
        <p className="my-3">{props.subheading}</p>
        </div>
    </>
  )
}
