import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import MainSubHeading from "../Sub-Heading/MainSubHeading";
import arkLogo from "../../assets/startups/arka aerospace.svg";
import univigor from "../../assets/startups/univigor.svg";
import flomobility from "../../assets/startups/flomobility.svg";
import photosplmedx from "../../assets/startups/photosplmedx.svg";
import sephirah from "../../assets/startups/sephirah.svg";
import metaverse from "../../assets/startups/metaverse.svg";
import blueballmedia from "../../assets/startups/Blueball Media.svg";
import Eranext from "../../assets/startups/eranexit.svg";
import nxtq from "../../assets/startups/nxtq pvt.svg";
import Xtraliving from "../../assets/startups/Xtraliving.svg";
import irvesh from "../../assets/startups/irvesh.svg";
import glibzter from "../../assets/startups/glibzter.svg";
import hummsa from "../../assets/startups/hummsa.svg";
import padmaseetha from "../../assets/startups/padmaseetha.svg";

import "./startups.css";
export default function Startups() {
  const subheadingData = "Startups";
  return (
    <Container fluid="md">
      <MainSubHeading subheading={subheadingData} />
      <Row>
        <Col xs={12} sm={6} md={6} lg={3}>
          <div className="p-2">
            <div className="startup-card p-2">
              <div className="startup-img p-3 py-1">
                <img src={arkLogo} alt="" className="img-fluid w-100" />
              </div>
              <h6>Arka Aerospace</h6>
            </div>
          </div>
        </Col>

        <Col xs={12} sm={6} md={6} lg={3}>
          <div className="p-2">
            <div className="startup-card p-2">
              <div className="startup-img p-3 py-1">
                <img src={univigor} alt="" className="img-fluid w-100" />
              </div>
              <h6>Univigor Innovative Private Limited</h6>
            </div>
          </div>
        </Col>

        <Col xs={12} sm={6} md={6} lg={3}>
          <div className="p-2">
            <div className="startup-card p-2">
              <div className="startup-img p-3 py-1">
                <img src={flomobility} alt="" className="img-fluid w-100" />
              </div>
              <h6>Flo Mobility</h6>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={6} md={6} lg={3}>
          <div className="p-2">
            <div className="startup-card p-2">
              <div className="startup-img p-3 py-1">
                <img src={photosplmedx} alt="" className="img-fluid w-100" />
              </div>
              <h6>PhotoSpIMeDx</h6>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={6} md={6} lg={3}>
          <div className="p-2">
            <div className="startup-card p-2">
              <div className="startup-img p-3 py-1">
                <img src={sephirah} alt="" className="img-fluid w-100" />
              </div>
              <h6>Sephirah Innovations Pvt Ltd</h6>
            </div>
          </div>
        </Col>

        <Col xs={12} sm={6} md={6} lg={3}>
          <div className="p-2">
            <div className="startup-card p-2">
              <div className="startup-img p-3 py-1">
                <img src={metaverse} alt="" className="img-fluid w-100" />
              </div>
              <h6>Metaverts</h6>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={6} md={6} lg={3}>
          <div className="p-2">
            <div className="startup-card p-2">
              <div className="startup-img p-3 py-1">
                <img src={blueballmedia} alt="" className="img-fluid w-100" />
              </div>
              <h6>Blueball Media</h6>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={6} md={6} lg={3}>
          <div className="p-2">
            <div className="startup-card p-2">
              <div className="startup-img p-3 py-1">
                <img src={hummsa} alt="" className="img-fluid w-100" />
              </div>
              <h6>Hummsa Biotech</h6>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={6} md={6} lg={3}>
          <div className="p-2">
            <div className="startup-card p-2">
              <div className="startup-img p-3 py-1">
                <img src={padmaseetha} alt="" className="img-fluid w-100" />
              </div>
              <h6>Padmaseetha Technologies</h6>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={6} md={6} lg={3}>
          <div className="p-2">
            <div className="startup-card p-2">
              <div className="startup-img p-3 py-1">
                <img src={Eranext} alt="" className="img-fluid w-100" />
              </div>
              <h6>Eranext Auto Private Limited</h6>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={6} md={6} lg={3}>
          <div className="p-2">
            <div className="startup-card p-2">
              <div className="startup-img p-3 py-1">
                <img src={irvesh} alt="" className="img-fluid w-100" />
              </div>
              <h6>Nirvesh Enterprises</h6>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={6} md={6} lg={3}>
          <div className="p-2">
            <div className="startup-card p-2">
              <div className="startup-img p-3 py-1">
                <img src={nxtq} alt="" className="img-fluid w-100" />
              </div>
              <h6>NxtQ Private Limited</h6>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={6} md={6} lg={3}>
          <div className="p-2">
            <div className="startup-card p-2">
              <div className="startup-img p-3 py-1">
                <img src={Xtraliving} alt="" className="img-fluid w-100" />
              </div>
              <h6>Xtraliving</h6>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={6} md={6} lg={3}>
          <div className="p-2">
            <div className="startup-card p-2">
              <div className="startup-img p-3 py-1">
                <img src={glibzter} alt="" className="img-fluid w-100" />
              </div>
              <h6>Glibz ImageTech Private Limited</h6>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
