import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import hubTeam from './hubTeam.js'
import "./hubTeam.css";
// import Dummy from "../../assets/faculty/dummy img.png";
import MainSubHeading from "../Sub-Heading/MainSubHeading";
export default function HubTeam() {
  const subheadingData = "Hub Team";
  console.log(hubTeam)

  return (
    <>
      <Container>
        <MainSubHeading subheading={subheadingData} />
        {/* faculty member profile start */}
        <Row>

        {
          hubTeam && hubTeam.map((index, key)=>{
            return(
              <Col xs={12} sm={6} md={6} className="p-4 p-sm-2 p-md-4">
            <div className="hubTeam-bx">
              <Row className="m-0 cus_hub_team_row">
                <Col xs={12} sm={12} md={5} lg={4} className="p-2">
                  <img src={index.img} alt="" className="img-fluid rounded-4" />
                </Col>
                <Col xs={12} sm={12} md={7} lg={8} className="p-2 pt-0 pt-md-4">
                  <h4 className="hubTeam-main-head fw-bold">
                    {index.name}
                  </h4>
                  <h6 className="hubTeam-sub-head">
                    {index.details}
                  </h6>
                </Col>
              </Row>
            </div>
          </Col>
            )
          })
        }
        </Row>

        {/* faculty member profile ends */}
      </Container>
    </>
  );
}
