import React from "react";
import { Container } from "react-bootstrap";
import MainSubHeading from "../Sub-Heading/MainSubHeading";
export default function Careers() {
  const subheadingData = "WORK WITH US"
  return (
    <div>
      <Container>
        <MainSubHeading subheading={subheadingData} />
        <div className="py-md-3">
          <p>
            IHub-Data is on the lookout for passionate and talented individuals who
            are ready to shape the future with innovation and creativity. If you
            are driven by a desire to make a meaningful impact, explore our
            exciting career opportunities by clicking <a href="https://thetalentpool.co.in/ihub/" target="_blank" rel="noreferrer" className="text-decoration-none fw-bold">here.</a>
          </p>
          <p>Please Note: Clicking the link will open a new tab.</p>
        </div>
      </Container>
    </div>
  );
}
